<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>User Registration Form</strong> </CCardHeader>
          <CCardBody v-if="isLoading" class="text-center">
            <CSpinner color="primary" size="lg" />
          </CCardBody>
          <CForm autocomplete="off" v-else>
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="First Name"
                    v-model="first_name"
                    placeholder="Enter first name..."
                    required
                    @keyup="check_first_name"
                    :addInputClasses="first_name_invalid ? 'is-invalid' : null"
                    :isValid="first_name ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Last Name"
                    v-model="last_name"
                    placeholder="Enter last name..."
                    required
                    @keyup="check_last_name"
                    :addInputClasses="last_name_invalid ? 'is-invalid' : null"
                    :isValid="last_name ? true : null"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="NID Number"
                    v-model="nid_number"
                    placeholder="Enter NID..."
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Bank Account"
                    v-model="bank_account"
                    placeholder="Enter bank account..."
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="Address"
                    v-model="address"
                    placeholder="Enter Address..."
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Contact No"
                    v-model="contact_no"
                    placeholder="Enter contact number..."
                     @keyup="check_contact_no"
                    :addInputClasses="contact_no_invalid ? 'is-invalid' : null"
                    :isValid="contact_no ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <div class="input-group" style="margin-top: 30px">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Role</label
                      >
                    </div>
                    <select
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="role_id"
                    >
                      <template v-for="role in roles">
                        <option
                          :key="role.id"
                          :value="role.id"
                          v-if="role.id !== 11"
                        >
                          {{ role.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="email"
                    autocomplete="off"
                    label="Email"
                    v-model="email"
                    placeholder="Enter Email..."
                    required
                    @keyup="check_email"
                    :addInputClasses="email_invalid ? 'is-invalid' : null"
                    :isValid="email ? true : null"
                  />
                </CCol>

                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Username"
                    v-model="username"
                    placeholder="Enter Username..."
                    required
                    @keyup="check_username"
                    :addInputClasses="username_invalid ? 'is-invalid' : null"
                    :isValid="username ? true : null"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="password"
                    autocomplete="off"
                    v-model="password"
                    label="Password"
                    placeholder="Enter Password..."
                    required
                    @keyup="check_password"
                    :addInputClasses="password_invalid ? 'is-invalid' : null"
                    :isValid="password ? true : null"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="password"
                    autocomplete="off"
                    v-model="password_confirm"
                    label="Confirm Password"
                    placeholder="Confirm Password..."
                    required
                    @keyup="check_password_confirm"
                    :addInputClasses="
                      password_confirm_invalid ? 'is-invalid' : null
                    "
                    :isValid="password_confirm ? true : null"
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-between">
                <CButton
                  type="reset"
                  size="sm"
                  color="danger"
                  @click="clear_form"
                  ><CIcon name="cil-ban" /> Reset</CButton
                >
                <CButton
                  size="sm"
                  color="primary"
                  @click="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Submit</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UserCreate",

  data() {
    return {
      email: "",
      username: "",
      address: null,
      nid_number: null,
      bank_account: null,
      last_name: null,
      first_name: null,
      password: null,
      contact_no: null,
      password_confirm: null,
      role_id: 10,
      email_invalid: false,
      username_invalid: false,
      last_name_invalid: false,
      first_name_invalid: false,
      password_invalid: false,
      contact_no_invalid: false,
      password_confirm_invalid: false,
      role_id_invalid: false,
    };
  },
  created() {
    if(this.$store.state.access_token){
    this.$store.dispatch("roles/getRoles");
  }
  },
  computed: {
    ...mapState({
      roles: (state) => state.roles.roles,
      isLoading: (state) => state.roles.isLoading,
      isSaving: (state) => state.users.isSaving,
      deleteModal: (state) => state.roles.deleteModal,
    }),
  },
  methods: {
    check_last_name() {
      if (!this.last_name) {
        this.last_name_invalid = true;
      } else {
        this.last_name_invalid = false;
      }
    },
    check_first_name() {
      if (!this.first_name) {
        this.first_name_invalid = true;
      } else {
        this.first_name_invalid = false;
      }
    },
    check_username() {
      if (!this.username) {
        this.username_invalid = true;
      } else {
        this.username_invalid = false;
      }
    },
    check_contact_no() {
      if (!this.contact_no) {
        this.contact_no_invalid = true;
      } else {
        this.contact_no_invalid = false;
      }
    },
    check_password() {
      if (!this.password) {
        this.password_invalid = true;
      } else {
        this.password_invalid = false;
      }
    },
    check_password_confirm() {
      if (!this.password_confirm) {
        this.password_confirm_invalid = true;
      } else if (this.password !== this.password_confirm) {
        this.password_confirm_invalid = true;
      } else {
        this.password_confirm_invalid = false;
      }
    },
    check_email() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.email).toLowerCase())) {
        this.email_invalid = false;
      } else {
        this.email_invalid = true;
      }
    },
    checkForm() {
      if (
        !this.email ||
        !this.username ||
        !this.last_name ||
        !this.first_name ||
        !this.contact_no ||
        !this.password ||
        !this.password_confirm ||
        !this.role_id
      ) {
        this.check_last_name();
        this.check_first_name();
        this.check_username();
        this.check_password();
        this.check_contact_no();
        this.check_password_confirm();
        this.check_email();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        this.$store
          .dispatch("users/saveUser", { 
            email: this.email,
            username: this.username,
            address: this.address,
            contact_no: this.contact_no,
            nid_number: this.nid_number,
            bank_account: this.bank_account,
            last_name: this.last_name,
            first_name: this.first_name,
            password: this.password,
            password_confirm: this.password_confirm,
            role_id: this.role_id,
          })
          .then((response) => {
            // if(response.status === 200){
            //   // this.clear_form();
            // }
            
          });
      } else {
        this.checkForm();
      }
    },
    clear_form() {
      this.email = null;
      this.username = null;
      this.address = null;
      this.nid_number = null;
      this.bank_account = null;
      this.last_name = null;
      this.first_name = null;
      this.contact_no = null;
      this.password = null;
      this.password_confirm = null;
    },
  },
};
</script>
 